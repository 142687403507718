import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CompaniesPage from "./pages/Company/CompaniesPage";
import CompanyDetailPage from "./pages/Company/CompanyDetailPage";
import InvoicesPage from "./pages/Invoice/InvoicesPage";
import RequestSuggestionForm from "./pages/RequestSuggestionForm/RequestSuggestionForm";
import Header from "./components/Header";
import "./styles/App.css";
import PaymentsPage from "./pages/Payment/PaymentsPage";
import LoginForm from "./pages/Login/LoginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/404";
import { checkAuthState } from "./redux/authActions";

const AppContent = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  if (isAuthenticated === null) {
    return (
      <p style={{ textAlign: "center", fontSize: "18px" }}>
        Uygulama yükleniyor...
      </p>
    );
  }

  return (
    <Router>
      {isAuthenticated ? (
        <Header>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/companies"
              element={
                <ProtectedRoute>
                  <CompaniesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/invoices"
              element={
                <ProtectedRoute>
                  <InvoicesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payments"
              element={
                <ProtectedRoute>
                  <PaymentsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/companies/:id"
              element={
                <ProtectedRoute>
                  <CompanyDetailPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/RequestSuggestionForm"
              element={
                <ProtectedRoute>
                  <RequestSuggestionForm />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Header>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="*" element={<LoginForm />} />
        </Routes>
      )}
    </Router>
  );
};

const App = () => {
  return (
    <>
      <ToastContainer />
      <AppContent />
    </>
  );
};

export default App;
