import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000/";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getInvoices = async (branchId) => {
  try {
    const response = await axios.get("invoices", {
      params: { branchId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Faturaları getirirken hata:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const addInvoice = async (invoiceData, branchId) => {
  try {
    const response = await axios.post("invoices/", invoiceData, {
      headers: { "branch-id": branchId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Fatura eklenirken hata:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deleteInvoice = async (invoiceId, branchId) => {
  try {
    const response = await axios.delete(`invoices/${invoiceId}`, {
      headers: { "branch-id": branchId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Fatura silinirken hata:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const apiUpdateInvoice = async (invoiceId, updatedData, branchId) => {
  try {
    const response = await axios.put(`invoices/${invoiceId}`, updatedData, {
      headers: { "branch-id": branchId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Fatura güncellenirken hata:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
