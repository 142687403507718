import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getbranch,
  addbranch,
  updatebranch,
  deletebranch,
} from "../../api/branch";

const savedBranch = localStorage.getItem("selectedBranch");

export const fetchbranch = createAsyncThunk("branch/fetchbranch", async () => {
  try {
    const response = await getbranch();
    return response;
  } catch (error) {
    throw new Error("Şubeler alınırken hata oluştu.");
  }
});

export const createbranch = createAsyncThunk(
  "branch/createbranch",
  async (branchData) => {
    try {
      const response = await addbranch(branchData);
      return response.data;
    } catch (error) {
      throw new Error("Şube eklenirken hata oluştu.");
    }
  }
);

export const modifybranch = createAsyncThunk(
  "branch/modifybranch",
  async (branchData) => {
    try {
      const response = await updatebranch(branchData);
      return response.data;
    } catch (error) {
      throw new Error("Şube güncellenirken hata oluştu.");
    }
  }
);

export const removebranch = createAsyncThunk(
  "branch/removebranch",
  async (branchId) => {
    try {
      await deletebranch(branchId);
      return branchId;
    } catch (error) {
      throw new Error("Şube silinirken hata oluştu.");
    }
  }
);

const branchSlice = createSlice({
  name: "branch",
  initialState: {
    list: [],
    selectedbranch: savedBranch ? JSON.parse(savedBranch) : null,
    loading: false,
    error: null,
  },
  reducers: {
    setSelectedbranch: (state, action) => {
      state.selectedbranch = action.payload;
      localStorage.setItem("selectedBranch", JSON.stringify(action.payload));
    },
    clearSelectedbranch: (state) => {
      state.selectedbranch = null;
      localStorage.removeItem("selectedBranch");
    },
    resetBranchState: (state) => {
      state.list = [];
      state.selectedbranch = null;
      state.loading = false;
      state.error = null;
      localStorage.removeItem("selectedBranch");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchbranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchbranch.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload)) {
          state.list = action.payload;
        } else {
          state.error = "Veri düzgün yüklenmedi.";
        }
      })
      .addCase(fetchbranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createbranch.fulfilled, (state, action) => {
        state.list.push(action.payload);
      })
      .addCase(modifybranch.fulfilled, (state, action) => {
        const index = state.list.findIndex(
          (branch) => branch._id === action.payload._id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(removebranch.fulfilled, (state, action) => {
        state.list = state.list.filter(
          (branch) => branch._id !== action.payload
        );
      });
  },
});

export const { setSelectedbranch, clearSelectedbranch, resetBranchState } =
  branchSlice.actions;
export default branchSlice.reducer;
