import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedbranch } from "../redux/slices/branchSlice";
import { useNavigate } from "react-router-dom";
import Card from "../components/Card";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list, loading, error, selectedbranch } = useSelector(
    (state) => state.branch
  );

  const [isRedirecting, setIsRedirecting] = useState(false);

  const handleBranchSelect = (branch) => {
    dispatch(setSelectedbranch(branch));
    setIsRedirecting(true);

    setTimeout(() => {
      navigate("/companies");
    }, 1500);
  };

  if (loading) {
    return (
      <>
        <Helmet>
          <title>Etkin Cari - Şube ve Tedarikçi Yönetimi</title>
          <meta
            name="description"
            content="Şubeleriniz ve tedarikçilerinizle olan ticari ilişkileri takip edebileceğiniz başlangıç seviyesi muhasebe platformu."
          />
          <meta
            name="keywords"
            content="şube yönetimi, tedarikçi yönetimi, cari hesap, fatura takibi, ödeme takibi"
          />
        </Helmet>
        <div className="flex flex-1 items-center justify-center h-screen px-4 text-center">
          <div>
            <h2 className="text-2xl font-bold text-blue-600 mb-4">
              Yükleniyor...
            </h2>
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 mx-auto"></div>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return <p>Hata: {error}</p>;
  }

  return (
    <>
      <Helmet>
        <title>Etkin Cari - Şube ve Tedarikçi Yönetimi</title>
        <meta
          name="description"
          content="Şubeleriniz ve tedarikçilerinizle olan ticari ilişkileri takip edebileceğiniz başlangıç seviyesi muhasebe platformu."
        />
        <meta
          name="keywords"
          content="şube yönetimi, tedarikçi yönetimi, cari hesap, fatura takibi, ödeme takibi"
        />
      </Helmet>
      <div>
        {isRedirecting ? (
          <div className="flex flex-1 items-center justify-center h-screen px-4 text-center">
            <div>
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Şirketler sayfasına yönlendiriliyorsunuz...
              </h2>
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 mx-auto"></div>
            </div>
          </div>
        ) : (
          <div className="cards-container" style={styles.cardsContainer}>
            {list.length > 0 ? (
              list.map((branch) => (
                <Card
                  key={branch._id}
                  branch={branch}
                  isSelected={selectedbranch?._id === branch._id}
                  onClick={() => handleBranchSelect(branch)}
                />
              ))
            ) : (
              <p className="text-center text-gray-500">
                Henüz bir şube bulunmuyor.
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const styles = {
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  },
};

export default HomePage;
