import React from "react";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>Hakkımızda - Etkin Cari</title>
        <meta
          name="description"
          content="Etkin Cari, ticari ilişkilerinizi düzenlemenize yardımcı olan modern bir muhasebe platformudur."
        />
        <meta
          name="keywords"
          content="hakkımızda, etkin cari, muhasebe platformu, ticari yönetim"
        />
      </Helmet>

      <section className="bg-gray-100 p-5 rounded-xl">
        <div className="container mx-auto bg-slate-200 rounded-xl p-5 md:p-10 shadow-lg">
          <div className="text-center mb-10">
            <h1 className="text-3xl md:text-4xl mt-5 font-bold text-gray-800">
              Hakkımızda
            </h1>
            <p className="mt-4 text-base md:text-lg text-gray-600 leading-relaxed">
              Etkin Cari, ticari ilişkilerinizi ve finansal işlemlerinizi daha
              kolay yönetmeniz için tasarlanmış modern bir platformdur. Muhasebe
              işlemlerini kolaylaştıran çözümler sunuyoruz.
            </p>
          </div>
          <div className="grid relative grid-cols-1 md:grid-cols-3 gap-10 items-start">
            <div className="col-span-1 text-center">
              <img
                src="/etkin_cari.webp"
                alt="Etkin Cari Görseli"
                className=" rounded-xl mx-auto shadow-lg w-full h-auto object-cover max-h-[550px]"
              />
            </div>
            <div className="col-span-2  space-y-10">
              <div>
                <h2 className="text-2xl md:text-3xl font-bold text-gray-800">
                  Etkin Cari'nin Amacı
                </h2>
                <p className="mt-4 text-gray-600 leading-relaxed">
                  İşletmeler için muhasebe ve finansal yönetim süreçlerini
                  kolaylaştıran bir araç sunuyoruz. Etkin Cari, işletmenizin
                  ihtiyaç duyduğu şeffaflık ve düzeni sağlayarak, finansal
                  işlemleri yönetmenizi ve analiz etmenizi kolaylaştırır.
                </p>
                <ul className="list-disc mt-6 pl-5 text-gray-600 space-y-3">
                  <li>Detaylı şube ve tedarikçi yönetimi.</li>
                  <li>Kullanıcı dostu bir arayüz ile kolay kullanım.</li>
                  <li>
                    Finansal verilerinizi görselleştiren raporlama araçları.
                  </li>
                  <li>Verimli bir ödeme ve fatura takip sistemi.</li>
                </ul>
              </div>
            </div>
            <div className="hidden md:block absolute right-4 bottom-4 bg-white p-4 rounded-lg shadow-md text-gray-600">
              <p className="text-sm">
                © {new Date().getFullYear()} Etkin Cari. Tüm hakları saklıdır.
                Design by{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-400 underline"
                  href="https://github.com/omerfaruk2626"
                >
                  Ömer Faruk Sivri
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
