import { createSlice } from "@reduxjs/toolkit";

const isAuthenticatedFromStorage =
  JSON.parse(localStorage.getItem("isAuthenticated")) || false;

const initialState = {
  user: null,
  isAuthenticated: isAuthenticatedFromStorage,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
      localStorage.setItem("isAuthenticated", true);
    },
    loginFailure: (state, action) => {
      state.user = null;
      state.isAuthenticated = false;
      state.error = action.payload;
      localStorage.setItem("isAuthenticated", false);
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.error = null;
      localStorage.setItem("isAuthenticated", false);
    },
  },
});

export const { loginSuccess, loginFailure, logout } = authSlice.actions;
export default authSlice.reducer;
