const API_URL = process.env.REACT_APP_API_URL;

export const getbranch = async () => {
  try {
    const response = await fetch(`${API_URL}branch`);

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Şubeler alınamadı");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Şubeler alınırken hata:", error);
    throw error;
  }
};

export const addbranch = async (branch) => {
  try {
    const response = await fetch(`${API_URL}branch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: branch.name }),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Şube eklenemedi");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Şube eklenirken hata:", error);
    throw error;
  }
};

export const updatebranch = async (branchData) => {
  try {
    const response = await fetch(`${API_URL}branch/${branchData._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: branchData.name }),
    });

    if (!response.ok) {
      throw new Error("Şube güncellenemedi");
    }

    return response.json();
  } catch (error) {
    console.error("Şube güncellenirken hata:", error);
    throw error;
  }
};

export const deletebranch = async (branchId) => {
  try {
    const response = await fetch(`${API_URL}branch/${branchId}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Şube silinemedi");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Şube silinirken hata:", error);
    throw error;
  }
};
