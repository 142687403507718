import React, { useState, useEffect, useCallback, useRef } from "react";
import CompaniesList from "../../components/Company/CompaniesList";
import CompanyForm from "../../components/Company/CompanyForm";
import { getCompanies, updateCompany } from "../../api/companies";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { FaTimes } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { fetchbranch, setSelectedbranch } from "../../redux/slices/branchSlice";

const CompaniesPage = () => {
  const dispatch = useDispatch();
  const selectedbranch = useSelector((state) => state.branch.selectedbranch);
  const { list: branches } = useSelector((state) => state.branch);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [isBranchDropdownOpen, setIsBranchDropdownOpen] = useState(false);
  const branchDropdownRef = useRef(null);
  const navigate = useNavigate();

  const fetchCompanies = useCallback(async () => {
    if (!selectedbranch?._id) return;
    const data = await getCompanies(selectedbranch._id);
    setCompanies(data);
  }, [selectedbranch?._id]);

  useEffect(() => {
    if (!branches.length) {
      dispatch(fetchbranch());
    }
    if (selectedbranch?._id) {
      fetchCompanies();
    }
  }, [selectedbranch, dispatch, fetchCompanies, branches]);

  const filteredCompanies = companies.filter(
    (company) =>
      company.branchId === selectedbranch?._id &&
      company?.name.toLowerCase().includes(searchName.toLowerCase())
  );

  const handleBranchSelect = (branch) => {
    if (selectedbranch && selectedbranch._id === branch._id) {
      setIsBranchDropdownOpen(false);
      return;
    }
    dispatch(setSelectedbranch(branch));
    setIsBranchDropdownOpen(false);
  };

  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
    navigate(`/companies/${company._id}`);
  };

  const handleCompanyAdded = (newCompany) => {
    setCompanies((prevCompanies) => [...prevCompanies, newCompany]);
    setShowModal(false);
    fetchCompanies();
  };

  const handleEditCompany = (company) => {
    if (!company._id) {
      console.error("Geçersiz şirket ID'si.");
      return;
    }
    setSelectedCompany(company);
    setShowModal(true);
  };

  const handleUpdateCompany = async (updatedCompany) => {
    const response = await updateCompany(updatedCompany);
    setCompanies((prevCompanies) =>
      prevCompanies.map((c) => (c._id === response._id ? response : c))
    );
    setShowModal(false);
    setSelectedCompany(null);
    fetchCompanies();
  };

  // const handleDeleteClick = async (company) => {
  //   try {
  //     const hasPaymentsOrInvoices = await checkCompanyPaymentsOrInvoices(
  //       company._id
  //     );

  //     if (hasPaymentsOrInvoices) {
  //       const { value: enteredPassword } = await Swal.fire({
  //         title: "Şifre Girişi",
  //         text: "Şirket ile ilgili kayıtlar vardır. Buna rağmen silmek istiyorsanız şifreyi girin:",
  //         input: "password",
  //         inputPlaceholder: "Şifrenizi girin",
  //         showCancelButton: true,
  //         confirmButtonText: "Onayla",
  //         cancelButtonText: "İptal",
  //         inputValidator: (value) => {
  //           if (!value) return "Şifreyi girmeniz gerekmektedir!";
  //         },
  //       });

  //       if (enteredPassword !== "Deneme123") {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Yanlış şifre!",
  //           text: "Yönlendiriliyorsunuz",
  //         });
  //         return;
  //       }
  //     }

  //     const confirmDelete = await Swal.fire({
  //       title: "Emin misiniz?",
  //       text: hasPaymentsOrInvoices
  //         ? "Ünvanı silmek istediğinizden emin misiniz?"
  //         : "Şirketi silmek istediğinizden emin misiniz?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Evet, Sil",
  //       cancelButtonText: "Hayır",
  //     });

  //     if (confirmDelete.isConfirmed) {
  //       await deactivateCompany(company._id);
  //       setCompanies((prevCompanies) =>
  //         prevCompanies.filter((c) => c._id !== company._id)
  //       );
  //       Swal.fire({
  //         icon: "success",
  //         title: "Başarılı!",
  //         text: "Şirket başarıyla silindi.",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Kayıt kontrolü sırasında bir hata oluştu:", error.message);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Hata",
  //       text: "Kayıt kontrolü sırasında bir hata oluştu.",
  //     });
  //   }
  // };

  // const checkCompanyPaymentsOrInvoices = async (companyId) => {
  //   try {
  //     const response = await fetch(`/api/companies/${companyId}/has-records`);
  //     const data = await response.json();
  //     return data.hasRecords;
  //   } catch (error) {
  //     console.error("Kayıtlar kontrol edilirken hata:", error);
  //     return false;
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>Şubeler - Etkin Cari</title>
        <meta
          name="description"
          content="Şubelerinizin ticari işlemlerini ve cari hesaplarını takip edin."
        />
        <meta
          name="keywords"
          content="şube yönetimi, ticari işlemler, borç alacak, cari hesap"
        />
      </Helmet>
      <div className="p-4 flex flex-col items-center h-screen">
        <h1 className="text-2xl font-bold mb-4 text-center">Şirketler</h1>

        {selectedbranch ? (
          <>
            <div className="w-full max-w-lg bg-white rounded-lg shadow-md">
              <div className="p-4 border-b border-gray-300">
                <div className="">
                  <input
                    type="text"
                    placeholder="Ünvan Ara"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    className="border z-10 rounded-lg w-full p-2 mb-2 pr-10"
                  />
                  {searchName && (
                    <button
                      onClick={() => setSearchName("")}
                      className="absolute inset-y-1 right-3 flex items-center pl-2"
                    >
                      <FaTimes className="text-gray-400 hover:text-gray-600" />
                    </button>
                  )}
                </div>
                <button
                  className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition w-full"
                  onClick={() => {
                    setSelectedCompany(null);
                    setShowModal(true);
                  }}
                >
                  Şirket Ekle
                </button>
              </div>

              <div className="border-b border-gray-300 pb-4 mb-4">
                <CompaniesList
                  companies={filteredCompanies}
                  onSelect={handleSelectCompany}
                  onEdit={handleEditCompany}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
            {/* Placeholder Icon */}
            <div className="text-gray-500 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-20 h-20"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 21h16.5M3.75 3v18m16.5-18v18M8.25 6h7.5M8.25 10.5h7.5M8.25 15h7.5"
                />
              </svg>
            </div>

            {/* Placeholder Text */}
            <h2 className="text-xl font-bold text-gray-800">
              Seçili bir şube bulunamadı
            </h2>
            <p className="text-gray-500 mt-2">
              Şirketleri görüntülemek için bir şube seçmeniz gerekiyor.
            </p>

            {/* Şube Seçme Butonu ve Açılır Menü */}
            <div className="relative mt-4" ref={branchDropdownRef}>
              <button
                onClick={() => setIsBranchDropdownOpen(!isBranchDropdownOpen)}
                className="bg-gray-700 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition"
              >
                {selectedbranch ? selectedbranch.name : "Şube Seçiniz"}
              </button>

              {isBranchDropdownOpen && (
                <ul className="absolute left-0 mt-2 bg-white text-gray-800 shadow-lg rounded-md w-48 z-40">
                  {branches.length > 0 ? (
                    branches.map((branch) => (
                      <li key={branch._id}>
                        <button
                          onClick={() => handleBranchSelect(branch)}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        >
                          {branch.name}
                        </button>
                      </li>
                    ))
                  ) : (
                    <li className="px-4 py-2 text-gray-500">Şube bulunamadı</li>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}

        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <CompanyForm
            onCompanyAdded={handleCompanyAdded}
            onUpdateCompany={handleUpdateCompany}
            initialData={selectedCompany}
            branchId={selectedbranch?._id}
          />
        </Modal>
      </div>
    </>
  );
};

export default CompaniesPage;
