import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { logoutUser } from "../redux/authActions";
import { fetchbranch, setSelectedbranch } from "../redux/slices/branchSlice";
import LogoutIcon from "@mui/icons-material/Logout";

const Header = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list: branch, selectedbranch } = useSelector((state) => state.branch);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    dispatch(fetchbranch());

    // Modal dışına tıklama kontrolü
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dispatch]);

  const handleBranchSelect = (branch) => {
    dispatch(setSelectedbranch(branch));
    setIsModalOpen(false);

    const currentPath = window.location.pathname;
    if (currentPath === "/" || currentPath === "/about") {
      Swal.fire({
        title: `${branch.name} Seçildi`,
        text: `Şirketler sayfasına yönlendiriliyor...`,
        icon: "success",
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
      }).then(() => {
        navigate("/companies");
      });
    }
  };

  const handleLogout = () => {
    navigate("/login");
    dispatch(logoutUser());
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="md:hidden bg-gray-900 text-white flex justify-between items-center p-4 shadow-md">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="text-white focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <div className="ms-4">
          <button
            onClick={() => setIsModalOpen(true)}
            className="p-2 bg-gray-800 text-white rounded-md"
          >
            {selectedbranch ? selectedbranch.name : "Şube Seçin"}
          </button>
        </div>
        <button
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
        >
          <LogoutIcon />
        </button>
      </div>

      {isMobileMenuOpen && (
        <nav className="absolute top-14 left-0 bg-gray-800 text-white w-full z-50 shadow-lg">
          <ul className="space-y-2 p-4">
            <li>
              <Link
                to="/"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Ana Sayfa
              </Link>
            </li>
            <li>
              <Link
                to="/companies"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Şirketler
              </Link>
            </li>
            <li>
              <Link
                to="/invoices"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Faturalar
              </Link>
            </li>
            <li>
              <Link
                to="/payments"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Ödemeler
              </Link>
            </li>
            <li>
              <Link
                to="/RequestSuggestionForm"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                İstek ve Öneri
              </Link>
            </li>
          </ul>
        </nav>
      )}

      <div className="hidden md:flex h-full">
        <nav className="w-64 bg-gray-900 text-white flex flex-col p-4 space-y-4">
          <div className="text-center text-2xl font-bold mb-4">Etkin Cari</div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="p-2 bg-gray-800 text-white rounded-md w-full text-left"
          >
            {selectedbranch ? selectedbranch.name : "Şube Seçin"}
          </button>
          <ul className="space-y-2 mt-4">
            <li>
              <Link
                to="/"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
              >
                Ana Sayfa
              </Link>
            </li>
            <li>
              <Link
                to="/companies"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
              >
                Şirketler
              </Link>
            </li>
            <li>
              <Link
                to="/invoices"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
              >
                Faturalar
              </Link>
            </li>
            <li>
              <Link
                to="/payments"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
              >
                Ödemeler
              </Link>
            </li>
            <li>
              <Link
                to="/RequestSuggestionForm"
                className="block px-4 py-2 hover:bg-gray-700 rounded"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                İstek ve Öneri
              </Link>
            </li>
          </ul>
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded mt-auto"
          >
            Çıkış Yap <LogoutIcon />
          </button>
        </nav>
        <main className="flex-1 bg-gray-100 p-4 overflow-y-auto">
          {children}
        </main>
      </div>

      <main className="md:hidden flex-1 bg-gray-100 p-4">{children}</main>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div
            ref={modalRef}
            className="bg-white rounded-lg shadow-lg p-4 w-[90%] max-w-md"
          >
            <h2 className="text-xl font-bold mb-4">Şube Seçin</h2>
            <ul className="space-y-2">
              {branch.map((b) => (
                <li
                  key={b._id}
                  className="hover:bg-gray-100 px-4 py-2 cursor-pointer border rounded"
                  onClick={() => handleBranchSelect(b)}
                >
                  {b.name}
                </li>
              ))}
            </ul>
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded w-full"
              onClick={() => setIsModalOpen(false)}
            >
              Kapat
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
