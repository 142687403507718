import React from "react";

const CompanyDetail = ({ company, sortedTransactions, formatCurrency }) => {
  let totalBalance = 0;

  return (
    <div>
      <h3 className="text-xl font-bold mt-4 mb-2">Ekstre</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2">Tarih</th>
              <th className="px-4 py-2">Belge No</th>
              <th className="px-4 py-2 text-right">Borç Tutar</th>
              <th className="px-4 py-2 text-right">Alacak Tutar</th>
              <th className="px-4 py-2 text-right">Bakiye</th>
              <th className="px-4 py-2">Açıklama</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedTransactions.length > 0 ? (
              sortedTransactions.map((transaction, index) => {
                let isPayment = transaction.type === "payment";
                if (isPayment) {
                  totalBalance -= transaction.amount;
                } else {
                  totalBalance += transaction.amount;
                }

                const balanceLabel = totalBalance < 0 ? "B" : "A";
                const absoluteBalance = Math.abs(totalBalance);

                const balanceColor =
                  totalBalance < 0 ? "text-red-500" : "text-green-500";

                return (
                  <tr key={transaction._id || index}>
                    <td className="px-4 py-2">
                      {transaction.date.toLocaleDateString("tr-TR")}
                    </td>
                    <td className="px-4 py-2">
                      {transaction.invoiceNumber || transaction.documentNumber}
                    </td>
                    <td className="px-4 py-2 text-right">
                      {isPayment
                        ? formatCurrency(transaction.amount)
                        : formatCurrency(0)}
                    </td>
                    <td className="px-4 py-2 text-right">
                      {!isPayment
                        ? formatCurrency(transaction.amount)
                        : formatCurrency(0)}
                    </td>
                    <td className="px-4 py-2 text-right">
                      <span className={balanceColor}>
                        {`${formatCurrency(absoluteBalance)} ${balanceLabel}`}
                      </span>
                    </td>
                    <td className="px-4 py-2">{transaction.description}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6} className="text-center px-4 py-2">
                  Hiç işlem bulunamadı.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompanyDetail;
