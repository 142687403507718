// src/pages/NotFound.js
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFound = () => (
  <>
    <Helmet>
      <title>Sayfa Bulunamadı - Etkin Cari</title>
      <meta
        name="description"
        content="Aradığınız sayfa bulunamadı. Lütfen doğru URL'yi kontrol edin."
      />
    </Helmet>
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-4xl font-bold mb-4">404</h1>
      <p className="text-lg mb-8">Aradığınız sayfa bulunamadı.</p>
      <Link to="/" className="text-blue-500 hover:underline">
        Ana Sayfaya Dön
      </Link>
    </div>
  </>
);

export default NotFound;
