import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices } from "../../redux/slices/invoicesSlice";
import { fetchbranch, setSelectedbranch } from "../../redux/slices/branchSlice";
import { getCompanies } from "../../api/companies";
import { deleteInvoice, apiUpdateInvoice } from "../../api/invoices";
import InvoiceList from "../../components/Invoice/InvoiceList";
import Modal from "../../components/Modal";
import InvoiceForm from "../../components/Invoice/InvoiceForm";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const InvoicesPage = () => {
  const dispatch = useDispatch();
  const { list: invoices, loading } = useSelector((state) => state.invoices);
  const { list: branches, selectedbranch } = useSelector(
    (state) => state.branch
  );

  const [companies, setCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isBranchDropdownOpen, setIsBranchDropdownOpen] = useState(false);
  const branchDropdownRef = useRef(null);

  const fetchCompanies = useCallback(async () => {
    try {
      if (!selectedbranch?._id) return;
      const data = await getCompanies(selectedbranch._id);
      setCompanies(data);
    } catch (error) {
      console.error("Şirketler alınırken hata:", error);
    }
  }, [selectedbranch]);

  const handleBranchSelect = (branch) => {
    if (selectedbranch && selectedbranch._id === branch._id) {
      setIsBranchDropdownOpen(false);
      return;
    }

    dispatch(setSelectedbranch(branch));
    setIsBranchDropdownOpen(false);
  };

  const onInvoiceDelete = async (invoiceId) => {
    try {
      await deleteInvoice(invoiceId, selectedbranch._id);
      dispatch(fetchInvoices(selectedbranch._id));
    } catch (error) {
      console.error("Fatura silinirken hata:", error.message);
      toast.error("Fatura silinirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const onInvoiceUpdate = async (invoiceId, updatedData) => {
    try {
      const updatedInvoice = await apiUpdateInvoice(
        invoiceId,
        updatedData,
        selectedbranch._id
      );
      if (updatedInvoice) {
        dispatch(fetchInvoices(selectedbranch._id));
      } else {
        console.error("Güncellenen fatura bulunamadı");
      }
    } catch (error) {
      console.error("Fatura güncellenirken hata:", error.message);
    }
  };

  useEffect(() => {
    if (!branches.length) {
      dispatch(fetchbranch());
    }
    if (selectedbranch?._id) {
      dispatch(fetchInvoices(selectedbranch._id));
      fetchCompanies();
    }
  }, [selectedbranch, dispatch, fetchCompanies, branches]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Fatura Yönetimi - Etkin Cari</title>
        <meta
          name="description"
          content="Tüm fatura işlemlerinizi tek bir yerden kolayca takip edin."
        />
        <meta
          name="keywords"
          content="fatura yönetimi, cari hesap, ticari işlemler"
        />
      </Helmet>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold text-center text-blue-800 mb-4">
          Fatura Listesi
        </h1>

        {selectedbranch ? (
          <>
            <div className="gap-4 flex">
              <button
                className="mb-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-300 hover:text-black transition"
                onClick={() => setShowModal(true)}
              >
                Fatura Ekle
              </button>
            </div>

            <InvoiceList
              invoices={invoices}
              companies={companies}
              onInvoiceUpdate={onInvoiceUpdate}
              onInvoiceDelete={onInvoiceDelete}
            />
          </>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
            <div className="text-gray-500 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-20 h-20"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 21h16.5M3.75 3v18m16.5-18v18M8.25 6h7.5M8.25 10.5h7.5M8.25 15h7.5"
                />
              </svg>
            </div>

            <h2 className="text-xl font-bold text-gray-800">
              Seçili bir şube bulunamadı
            </h2>
            <p className="text-gray-500 mt-2">
              Faturaları görüntülemek için bir şube seçmeniz gerekiyor.
            </p>

            <div className="relative mt-4" ref={branchDropdownRef}>
              <button
                onClick={() => setIsBranchDropdownOpen(!isBranchDropdownOpen)}
                className="bg-gray-700 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition"
              >
                {selectedbranch ? selectedbranch.name : "Şube Seçiniz"}
              </button>

              {isBranchDropdownOpen && (
                <ul className="absolute left-0 mt-2 bg-white text-gray-800 shadow-lg rounded-md w-48 z-40">
                  {branches.length > 0 ? (
                    branches.map((branch) => (
                      <li key={branch._id}>
                        <button
                          onClick={() => handleBranchSelect(branch)}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        >
                          {branch.name}
                        </button>
                      </li>
                    ))
                  ) : (
                    <li className="px-4 py-2 text-gray-500">Şube bulunamadı</li>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}

        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <InvoiceForm
            companies={companies}
            onInvoiceAdded={() => dispatch(fetchInvoices(selectedbranch._id))}
            closeModal={() => setShowModal(false)}
            selectedbranchId={selectedbranch?._id}
          />
        </Modal>
      </div>
    </>
  );
};

export default InvoicesPage;
