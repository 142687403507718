import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addPayment, getPayments, updatePayment } from "../../api/payments";

export const fetchPayments = createAsyncThunk(
  "payments/fetchPayments",
  async ({ branchId }, thunkAPI) => {
    if (!branchId) {
      return thunkAPI.rejectWithValue("Şube ID'si gerekli");
    }
    try {
      const response = await getPayments(branchId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createPayment = createAsyncThunk(
  "payments/createPayment",
  async (payment, thunkAPI) => {
    try {
      const response = await addPayment(
        payment,
        payment.branchId,
        payment.companyId
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updatePaymentAction = createAsyncThunk(
  "payments/updatePayment",
  async (paymentData) => {
    const response = await updatePayment(paymentData);
    return response;
  }
);

const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    payments: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.payments = action.payload;
        state.loading = false;
      })
      .addCase(fetchPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updatePaymentAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePaymentAction.fulfilled, (state, action) => {
        const index = state.payments.findIndex(
          (payment) => payment._id === action.payload._id
        );
        if (index !== -1) {
          state.payments[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updatePaymentAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default paymentsSlice.reducer;
