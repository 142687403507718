import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/authActions";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { loginFailure } from "../../redux/slices/authslice";
import About from "../About";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, isAuthenticated } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("admin@admin.com");
  const [password, setPassword] = useState("admin26");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      Swal.fire({
        icon: "success",
        title: "Giriş Başarılı",
        text: "Hoş Geldiniz!",
        showConfirmButton: false,
        timer: 1500,
      });

      setIsModalOpen(false);
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Giriş Başarısız",
        text: error,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(loginFailure(null));

    try {
      await dispatch(loginUser(email, password));
      localStorage.setItem("isAuthenticated", "true");
      setLoading(false);
      navigate("/");
    } catch (err) {
      console.error("Giriş hatası:", err);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Giriş Yapılamadı",
        text: "Bir hata oluştu, lütfen tekrar deneyin.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-500 to-purple-700 text-white">
      <header className="w-full text-center py-6">
        <h1 className="text-4xl font-bold">Hoş Geldiniz</h1>
        <p className="mt-2 text-lg">
          İşlerinizi kolaylaştıran modern bir platform.
        </p>
        <button
          className="mt-6 bg-white text-blue-700 font-bold px-6 py-2 rounded-lg hover:bg-gray-200 transition duration-200"
          onClick={() => setIsModalOpen(true)}
        >
          Sisteme Giriş
        </button>
      </header>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-[350px]">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
              Giriş Yap
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  E-Posta
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-300 rounded w-full px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="E-posta adresinizi girin"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Şifre
                </label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-gray-300 rounded w-full px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Şifrenizi girin"
                  required
                />
              </div>

              <button
                type="submit"
                className={`${
                  loading
                    ? "bg-blue-400 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                } text-white font-bold py-2 px-4 rounded w-full transition duration-200`}
                disabled={loading}
              >
                {loading ? "Giriş Yapılıyor..." : "Giriş Yap"}
              </button>
            </form>
            <button
              className="mt-4 text-sm text-gray-500 hover:underline w-full text-center"
              onClick={() => setIsModalOpen(false)}
            >
              Kapat
            </button>
          </div>
        </div>
      )}
      <section className="container mx-auto mt-8 p-4">
        <About />
      </section>
      <footer className="block md:hidden  left-0 w-full bg-white text-center text-gray-600 py-2">
        <p className="text-sm">
          © {new Date().getFullYear()} Etkin Cari. Tüm hakları saklıdır. Design
          by{" "}
          <a
            rel="noreferrer"
            target="_blank"
            className="text-blue-400 underline"
            href="https://github.com/omerfaruk2626"
          >
            Ömer Faruk Sivri
          </a>
        </p>
      </footer>
    </div>
  );
};

export default LoginPage;
