import { configureStore } from "@reduxjs/toolkit";
import companiesReducer from "./slices/companiesSlice";
import invoicesReducer from "./slices/invoicesSlice";
import paymentsReducer from "./slices/paymentsSlice";
import branchReducer from "./slices/branchSlice";
import authReducer from "./slices/authslice";

const store = configureStore({
  reducer: {
    companies: companiesReducer,
    invoices: invoicesReducer,
    payments: paymentsReducer,
    branch: branchReducer,
    auth: authReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
