import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const PaymentForm = ({
  companies,
  onAddPayment,
  closeModal,
  selectedbranchId,
}) => {
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date());
  const [companyId, setCompanyId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const generateDocumentNumber = () => `PAY-${Date.now().toString().slice(-6)}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (!companyId) {
      toast.error("Şirket seçilmelidir.");
      return;
    }

    const newPayment = {
      amount: parseFloat(amount),
      companyId,
      branchId: selectedbranchId,
      date,
      documentNumber: generateDocumentNumber(),
      description: description.toUpperCase(),
    };

    try {
      setIsSubmitting(true);
      await onAddPayment(newPayment);
      setAmount("");
      setDescription("");
      setDate(new Date());
      closeModal();
    } catch (error) {
      toast.error("Ödeme eklenirken bir hata oluştu.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (companies.length > 0 && !companyId) {
      setCompanyId(companies[0]._id);
    }
  }, [companies, companyId]);

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 border p-5 rounded shadow-md"
    >
      <div className="mb-4">
        <label className="block">Şirket</label>
        <select
          value={companyId}
          onChange={(e) => setCompanyId(e.target.value)}
          required
          className="w-full border px-4 py-2"
        >
          {companies.map((company) => (
            <option key={company._id} value={company._id}>
              {company.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label className="block">Ödeme Tutarı</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          className="w-full border px-4 py-2"
          step="0.01"
          min="0"
          max="99999999"
        />
      </div>
      <div>
        <label className="block">Açıklama</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="w-full border px-4 py-2"
          maxLength={50}
        />
      </div>
      <div>
        <label className="block">Ödeme Tarihi</label>
        <input
          type="date"
          value={date.toISOString().split("T")[0]}
          onChange={(e) => setDate(new Date(e.target.value))}
          required
          className="w-full border px-4 py-2"
        />
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={closeModal}
          className="bg-gray-500 text-white px-4 py-2 rounded"
        >
          Kapat
        </button>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded"
          disabled={companies.length === 0}
        >
          Ödeme Ekle
        </button>
      </div>
    </form>
  );
};

export default PaymentForm;
